import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ConceptosGastosCreate() {
  const { user } = useContext(AuthContext);
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);
  const [t, i18n] = useTranslation("global");
  const clear = () => {
    setName("");
  };

  const saveConceptosGastos = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_CONCEPTOS_GASTOS,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Concepto Gastos",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">{t("Crea un Concepto")}</h3>
        <Form onSubmit={saveConceptosGastos}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              {t("Concepto")}
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder={t("Nombre del Concepto")}
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormGroup>
          <Row className="container">
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                {t("Guardar")}
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                {t("Guardar")}
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/MenuContenedores">
            {t("Regresar")}
            </Button>
            </Row>
        </Form>
      </div>
    </>
  );
}

export default ConceptosGastosCreate;
