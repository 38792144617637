import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function GastosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [totalUSD, setTotalUSD] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [moneda, setMoneda] = useState("MXN");

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });


      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    }, []);

  const calcula = (e) => {
    setTotalUSD(e);
    setTotal(parseFloat(e) * parseFloat(tipoCambio));
  };

  const calculaTipoCambio = (e) => {
    setTipoCambio(e);
    setTotal(parseFloat(totalUSD) * parseFloat(e));
  };

  const saveGasto = (event) => {
    event.preventDefault();
    setValidaBoton(false);
        axios.post(
          URL_GASTOS,
          {
            fecha,
            totalUSD,
            tipoCambio,
            total,
            abonos:0,
            saldo:total,
            descripcion,
            conceptosGastos:selectedConcepto,
            proveedores: selectedProveedor,
            moneda
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        // axios.post(
        //   URL_LOGS,
        //   {
        //     tipo: "Crear Gasto",
        //     detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
        //     user: user.id,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //     },
        //   }
        // );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastosCreate ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">{t("Nuevo Gasto")}</h3>
            <Form onSubmit={saveGasto}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>

                <Col md={6}>
                    <Label className="mr-sm-2">
                      {t("Fecha")}
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                 

                      <Col md={6}>
                    <Label className="mr-sm-2">Proveedor</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedProveedor}
                        onChange={(e) => setSelectedProveedor(e.target.value)}
                      >
                        <option value="0">{t("Selecciona")}</option>
                        {proveedores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>

                    
                <Col md={6}>
                    <Label className="mr-sm-2">{t("Concepto")}</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedConcepto}
                        onChange={(e) => setSelectedConcepto(e.target.value)}
                      >
                        <option value="0">{t("Selecciona")}</option>
                        {conceptosGastos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>

               
                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      {t("Descripcion")}
                    </Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                <Col md={6}>
                    <Label className="mr-sm-2">{t("Moneda")}</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={moneda}
                        onChange={(e) => {
                          setMoneda(e.target.value)
                        setTotalUSD(0)
                        setTotal(0)
                        setTipoCambio(1)}
                        }
                      >
                        <option value="MXN">{t("MXN")}</option>
                        <option value="USD">{t("USD")}</option>
                      </Input>
                      </Col>
                      </Row>
                      {moneda === "USD" ? (
                        
                        <Row>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Total USD")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            placeholder="TotalUSD"
                            value={totalUSD}
                            required
                            onChange={(e) => calcula(e.target.value)}
                          />
                        </Col>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            Tipo de Cambio
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            placeholder="TipoCambio"
                            value={tipoCambio}
                            required
                            onChange={(e) => calculaTipoCambio(e.target.value)}
                          />
                        </Col>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Total MXN")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            value={total}
                            disabled
                            // onChange={(e) => calculaMXN(e.target.value)}
                          />
                        </Col>
                      </Row>
                      ): (
                        
                        <Row>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Total MXN")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            value={total}
                            onChange={(e) => {setTotal(e.target.value)
                    setTotalUSD(e.target.value/tipoCambio)
                  }}
                          />
                        </Col>
                      </Row>
                      )}
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      {t("Guardar")}
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      {t("Guardar")}
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/Gastos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                     {t("Regresar")}
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default GastosCreate;
