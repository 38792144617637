import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function ListadoGastosContenedor() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS_CONTENEDOR = process.env.REACT_APP_URL_GASTOS_CONTENEDOR;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);
  let inicio2 = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(inicio2);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState(hoy);
  const [totalUSD, setTotalUSD] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");
  const [contenedores, setContenedores] = useState([]);
  const [selectedContenedor, setSelectedContenedor] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [moneda, setMoneda] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_GASTOS_CONTENEDOR}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allGastos = response.data;
        let arrayTabla = allGastos
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            const fechaGasto = new Date(a.fecha);
            // const fechaActual = new Date();
            const fechaActual = new Date();
  
            // Fecha límite de edición (15 del mes anterior o 15 del mes actual)
            let mesEdicion = fechaActual.getDate() < 15
              ? fechaActual.getMonth() - 1
              : fechaActual.getMonth();
            let year = fechaActual.getFullYear();
    
            if (mesEdicion < 0) {
              mesEdicion = 11; // Diciembre
              year -= 1; // Año anterior
            }
    
           // Convertir mes y día a formato de dos dígitos
          const mesEdicionFormatted = (mesEdicion + 1).toString().padStart(2, '0'); // `+1` porque los meses en JavaScript son 0-11
          const diaEdicionFormatted = '15';
  
          // Formatear la fecha límite de edición a AAAA-MM-DD
          const fechaLimiteEdicion = `${year}-${mesEdicionFormatted}-${diaEdicionFormatted}`;
  
          // Convertir la fecha del gasto a formato AAAA-MM-DD para la comparación
          const fechaGastoFormatted = fechaGasto.toISOString().split('T')[0];
  
          // Determinar si se puede editar
          const poderEditar =
          fechaGastoFormatted > fechaLimiteEdicion || a.tipoCambio === 0 ? "Si" : "No";

            return {
              _id: a._id,
              numero: a.idGastoContenedor,
              fecha: a.fecha,
              descripcion: a.descripcion,
              totalUSD: a.totalUSD,
              tipoCambio: a.tipoCambio,
              total: a.total,
              concepto: a.conceptosGastos[0].name,
              cliente: a.clientes[0].nombre_comercial,
              idCliente: a.clientes[0]._id,
              contenedor: a.contenedores[0].numeroInterno,
              idContenedor: a.contenedores[0]._id,
              proveedor: a.proveedores[0].name,
              idProveedor: a.proveedores[0]._id,
              idConceptosGastos: a.conceptosGastos[0]._id,
              moneda: a.moneda,
              saldo: a.saldo,
              is_active: a.is_active,
              poderEditar: poderEditar,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CONTENEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allContenedores = res.data;
        setContenedores(allContenedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  let totalTabla = 0;

  function PDFTabla() {
    let total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          roundingIncrement: 5,
        }).format(c.total);
        return [c.fecha, c.concepto, c.descripcion, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Concepto", "Descripcion", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            roundingIncrement: 5,
          }).format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleGastos.pdf");
  }

  function excel() {
    const dataExcel = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        return {
          Fecha: c.fecha,
          Concepto: c.concepto,
          Descripcion: c.descripcion,
          Importe: c.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleGastos-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleGastos`,
        sheetFilter: ["Fecha", "Concepto", "Descripcion", "Importe"],
        sheetHeader: ["Fecha", "Concepto", "Descripcion", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          roundingIncrement: 5,
        }).format(c.total);
        return [c.fecha, c.concepto, c.descripcion, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Concepto", "Descripcion", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            roundingIncrement: 5,
          }).format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Gastos",
          email: mailTo,
          fileName: "ListaGastos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Gastos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const editGasto = (event) => {
    const URL_GASTOS_CONTENEDOR_EDIT = `${process.env.REACT_APP_URL_GASTOS_CONTENEDOR}/${idEdit}`;
    event.preventDefault();

    axios
      .patch(
        URL_GASTOS_CONTENEDOR_EDIT,
        {
          fecha: hoy,
          totalUSD,
          tipoCambio,
          total,
          abonos: 0,
          saldo: total,
          descripcion,
          conceptosGastos: selectedConcepto,
          contenedores: selectedContenedor,
          proveedores: selectedProveedor,
          clientes: selectedCliente,
          moneda,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Edit Gasto",
            detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  const headers = [
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("Concepto")}`, field: "concepto", sortable: true },
    { name: `${t("Descripcion")}`, field: "descripcion", sortable: true },
    { name: `${t("Contenedor")}`, field: "contenedor", sortable: true },
    { name: `${t("Cliente")}`, field: "cliente", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "TC", field: "tipoCambio", sortable: true },
    { name: `${t("Importe")}`, field: "total", sortable: true },
    { name: `${t("Editar")}`, field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          comment.total.toString().includes(search) ||
          comment.tipoCambio.toString().includes(search)
      );
    }

    setTotalItems(computedComments.length);

 //Sorting comments
 if (
  sorting.field &&
  sorting.field != "total" &&
  sorting.field != "tipoCambio"
) {
  const reversed = sorting.order === "asc" ? 1 : -1;
  computedComments = computedComments.sort(
    (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  );
}

if (
  sorting.field &&
  sorting.order === "asc" &&
  (sorting.field == "total" ||
    sorting.field == "tipoCambio")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
  );
}

if (
  sorting.field &&
  sorting.order === "desc" &&
  (sorting.field == "total" ||
    sorting.field == "tipoCambio")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
  );
}

//Current Page slice
return computedComments.slice(
  (currentPage - 1) * ITEMS_PER_PAGE,
  (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
);
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
  ]);



  const calcula = (e) => {
    setTotalUSD(e);
    setTotal(parseFloat(e) * parseFloat(tipoCambio));
  };

  const calculaTipoCambio = (e) => {
    setTipoCambio(e);
    setTotal(parseFloat(totalUSD) * parseFloat(e));
  };

  function jalaContenedor(e) {
    setSelectedContenedor(e);

    contenedores.map((a) => {
      if (a._id == e) {
        // setSelectedProveedor(a.proveedores[0]._id);
        setSelectedCliente(a.clientes[0]._id);
      }
    })
  }

  function cancelarGastoContenedor(idGastoContenedor) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Gasto Contenedor!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_GASTOS_CONTENEDOR}Cancel/${idGastoContenedor}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                   
                    <Button
                      size="sm"
                      href="/MenuCXP"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      {t("Regresar")}
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>{t(<Header />)}</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      {t("Enviar")}
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">{t("Gastos")}</h3>
                <Row>
                  <Col md={2}>
                    <Label>{t("Fecha Inicio")}</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>{t("Fecha Fin")}</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.total;
                       if(c.is_active == "Si"){
                      return (
                        <tr>
                          <td>{c.numero}</td>
                          <td>{c.fecha}</td>
                          <td>{c.concepto}</td>
                          <td>{c.descripcion}</td>
                          <td>{c.contenedor}</td>
                          <td>{c.cliente}</td>
                          <td>{c.proveedor}</td>
                          <td>{c.tipoCambio}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              roundingIncrement: 5,
                            }).format(c.total)}
                          </td>
                          <td>
                          {c.saldo == c.total && c.poderEditar == "Si" ? (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) => cancelarGastoContenedor(c._id)}
                              >
                                <i class="fas fa-ban"></i>
                          </Button>
                            ):(
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                                // onClick={(e) => cancelarGasto(c._id)}
                              >
                                <i class="fas fa-ban"></i>
                          </Button>
                            )}
                            {c.saldo == c.total && c.poderEditar == "Si" ?(
                          <Button
                        color="info"
                        id="Editar"
                        size="sm"
                        onClick={(e) => {
                          setIdEdit(c._id)
                          setFecha(c.fecha)
                          setTotalUSD(c.totalUSD)
                          setTipoCambio(c.tipoCambio)
                          setTotal(c.total)
                          setDescripcion(c.descripcion)
                          setSelectedConcepto(c.idConceptosGastos)
                          setSelectedContenedor(c.idContenedor)
                          setSelectedProveedor(c.idProveedor)
                          setSelectedCliente(c.idCliente)
                          setMoneda(c.moneda)
                          toggleEdit()
                        }}
                      >
                        <i class="fas fa-edit"></i>
                      </Button>
                      ):undefined}
                          </td>
                        </tr>
                      );
                       }else{
                        return (
                        <tr style={{ backgroundColor: "#ed8c8c"}}>
                          <td>{c.numero}</td>
                          <td>{c.fecha}</td>
                          <td>{c.concepto}</td>
                          <td>{c.descripcion}</td>
                          <td>{c.contenedor}</td>
                          <td>{c.cliente}</td>
                          <td>{c.proveedor}</td>
                          <td>{c.tipoCambio}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              roundingIncrement: 5,
                            }).format(c.total)}
                          </td>
                          <td>
                          CANCELADO
                          </td>
                        </tr>
                      );
                       }
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                          roundingIncrement: 5,
                        }).format(totalTabla)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4> {t("Editar Gasto")}</h4>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">{t("Fecha")}</Label>
                  <Input
                    type="date"
                    placeholder="Fecha"
                    value={fecha}
                    required
                    disabled
                  />
                </Col>
                <Col md={6}>
                  <Label className="mr-sm-2">{t("Contenedor")}</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    required
                    value={selectedContenedor}
                    disabled
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {contenedores
                      .sort((a, b) =>
                        a.numeroInterno > b.numeroInterno ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.numeroInterno}</option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Proveedor</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    required
                    value={selectedProveedor}
                    disabled
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {proveedores
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={6}>
                  <Label className="mr-sm-2">{t("Cliente")}</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    required
                    value={selectedCliente}
                    disabled
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">{t("Concepto")}</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    required
                    value={selectedConcepto}
                    onChange={(e) => setSelectedConcepto(e.target.value)}
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {conceptosGastos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={6}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    {t("Descripcion")}
                  </Label>
                  <Input
                    type="text"
                    placeholder="Descripcion"
                    value={descripcion}
                    required
                    onChange={(e) => {
                      setDescripcion(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
            <Col md={6}>
              <Label className="mr-sm-2">{t("Moneda")}</Label>
              <Input
                // bsSize="sm"
                type="select"
                required
                value={moneda}
                onChange={(e) => {
                  setMoneda(e.target.value);
                  setTotalUSD(0);
                  setTotal(0);
                  setTipoCambio(1);
                }}
              >
                <option value="MXN">{t("MXN")}</option>
                <option value="USD">{t("USD")}</option>
              </Input>
            </Col>
          </Row>
          {moneda === "USD" ? (
            <Row>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  {t("Total USD")}
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="TotalUSD"
                  value={totalUSD}
                  required
                  onChange={(e) => calcula(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Tipo de Cambio
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="TipoCambio"
                  value={tipoCambio}
                  required
                  onChange={(e) => calculaTipoCambio(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  {t("Total MXN")}
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  value={total}
                  disabled
                  // onChange={(e) => calculaMXN(e.target.value)}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  {t("Total MXN")}
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  value={total}
                  onChange={(e) => {setTotal(e.target.value)
                    setTotalUSD(e.target.value/tipoCambio)
                  }}
                />
              </Col>
            </Row>
          )}
             
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editGasto}>
                {t("Guardar")}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoGastosContenedor;
