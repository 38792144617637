import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Catalgos
import BotonesCatalogos from './views/Catalogos/BotonesCatalogos'
import ListadoArticulos from "./views/Catalogos/Articulos/ListadoArticulos";
import ListadoNavieras from "./views/Catalogos/Navieras/ListadoNavieras";
import ListadoEmpresas from "./views/Catalogos/Empresas/ListadoEmpresas";
import ListadoProveedores from "./views/Catalogos/Proveedores/ListadoProveedores";
import ListadoClientes from "./views/Catalogos/Clientes/ListadoClientes"
import ClientesCreate from "./views/Catalogos/Clientes/ClientesCreate"
import ListadoAgentes from "./views/Catalogos/Agentes/ListadoAgentes";
import ListadoForwarders from "./views/Catalogos/Forwarders/ListadoForwarders";

// Retiros
import BotonesRetiros from "./views/Retiros/BotonesRetiros.jsx";
import ListadoSocios from "./views/Retiros/Socios/ListadoSocios.jsx";


// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin"
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import ListadoMovimientosBancos from "./views/Admin/Bancos/ListadomovimientosBancos";
import ListadoCargosContenedor from './views/Admin/CargosContenedor/ListadoCargosContenedor'
import ListadoAbonos from "./views/Admin/Abonos/ListadoAbonos"
import AbonosCreate from "./views/Admin/Abonos/AbonosCreate"
import ListadoCargos from "./views/Admin/Cargos/ListadoCargos.jsx";
import CargosCreate from "./views/Admin/Cargos/CargosCreate.jsx";
import ListadoDevoluciones from "./views/Admin/Devoluciones/ListadoDevoluciones.jsx";
import DevolucionesCreate from "./views/Admin/Devoluciones/DevolucionesCreate.jsx";
import ListadoContenedoresAdmin from "./views/Admin/ContenedoresAdmin/ListadoContenedoresAdmin.jsx";
import ListadoAnticipos from "./views/Admin/Anticipos/ListadoAnticipos.jsx";
import AnticiposCreate from "./views/Admin/Anticipos/AnticiposCreate.jsx";
import ListadoDevolucionesInversa from "./views/Admin/DevolucionesInversa/ListadoDevolucionesInversa.jsx";
import DevolucionesInversaCreate from "./views/Admin/DevolucionesInversa/DevolucionesInversaCreate.jsx";

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP"
import ListadoGastosContenedor from './views/CXP/GastosContenedor/ListadoGastosContenedor'
import GastosContenedorCreate from './views/CXP/GastosContenedor/GastosContenedorCreate'
import ListadoConceptosGastos from './views/CXP/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/CXP/ConceptosGastos/ConceptosGastosCreate'
import ListadoPagos from "./views/CXP/Pagos/ListadoPagos";
import PagosCreate from "./views/CXP/Pagos/PagosCreate";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import ListadoGastos from './views/CXP/Gastos/ListadoGastos'
import GastosCreate from './views/CXP/Gastos/GastosCreate'
import ListadoAnticiposProveedores from "./views/CXP/AnticiposProveedores/ListadoAnticiposProveedores.jsx";
import AnticiposProveedoresCreate from "./views/CXP/AnticiposProveedores/AnticiposProveedoresCreate.jsx";
import BonificacionesProveedoresCreate from "./views/CXP/BonificacionesProveedores/BonificacionesProveedoresCreate.jsx";
import ListadoBonificacionesProveedores from "./views/CXP/BonificacionesProveedores/ListadoBonificacionesProveedores.jsx";


//Reportes
import BotonesReportes from "./views/Reportes/BotonesReportes";
import ReporteContenedores from "./views/Reportes/ReporteContenedores";
import ReporteContenedoresCargos from "./views/Reportes/ReporteContenedoresCargos";
import ReporteTransferencias from "./views/Reportes/ReporteTransferencias";
import ReporteFacturas from "./views/Reportes/ReporteFacturas.jsx";
import ReporteUtilidades from "./views/Reportes/ReporteUtilidades.jsx";

// Whatsapp
import Whatsapp from "./views/WhatsApp/Whatsapp";

// Contenedores
import BotonesContenedores from "./views/Contenedores/BotonesContenedores";
import ContenedoresCreate from "./views/Contenedores/Contenedores/ContenedoresCreate";
import ListadoContenedores from "./views/Contenedores/Contenedores/ListadoContenedores";
import ListadoTerminales from "./views/Contenedores/Terminales/ListadoTerminales";
import ListadoProgramas from "./views/Contenedores/Programas/ListadoProgramas.jsx";
import ListadoPatentes from "./views/Contenedores/Patentes/ListadoPatentes.jsx";

// Retiros
import ListadoRetiros from "./views/Retiros/Retiros/ListadoRetiros.jsx";
import RetirosCreate from "./views/Retiros/Retiros/RetirosCreate.jsx";
import ListadoPorRetirar from "./views/Retiros/PorRetirar/ListadoPorRetirar.jsx";
import PorRetirarCreate from "./views/Retiros/PorRetirar/PorRetirarCreate.jsx";




function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                 {/* Catalogos */}
                 <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                 <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                 <Route exact path ="/ListadoNavieras" component={ListadoNavieras} />
                 <Route exact path ="/ListadoEmpresas" component={ListadoEmpresas} />
                 <Route exact path ="/ListadoProveedores" component={ListadoProveedores} />
                 <Route exact path ="/ListadoClientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                <Route exact path ="/ListadoAgentes" component={ListadoAgentes} />
                <Route exact path ="/ListadoForwarders" component={ListadoForwarders} />

                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/MovimientosBancos" component={ListadoMovimientosBancos} />
                <Route exact path ="/Cargos" component={ListadoCargos} />
                <Route exact path ="/CargosCreate" component={CargosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />
                <Route exact path ="/ListadoContenedoresAdmin" component={ListadoContenedoresAdmin} />
                <Route exact path ="/ListadoAnticipos" component={ListadoAnticipos} />
                <Route exact path ="/AnticiposCreate" component={AnticiposCreate} />
                <Route exact path ="/ListadoDevolucionesInversa" component={ListadoDevolucionesInversa} />
                <Route exact path ="/DevolucionesInversaCreate" component={DevolucionesInversaCreate} />

                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/GastosContenedor" component={ListadoGastosContenedor} />
                <Route exact path ="/GastosContenedorCreate" component={GastosContenedorCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                <Route exact path ="/PagosCreate" component={PagosCreate} />
                <Route exact path ="/ListadoPagos" component={ListadoPagos} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/Gastos" component={ListadoGastos} />
                <Route exact path ="/GastosCreate" component={GastosCreate} />
                <Route exact path ="/ListadoAnticiposProveedores" component={ListadoAnticiposProveedores} />
                <Route exact path ="/AnticiposProveedoresCreate" component={AnticiposProveedoresCreate} />
                <Route exact path ="/ListadoBonificacionesProveedores" component={ListadoBonificacionesProveedores} />
                <Route exact path ="/BonificacionesProveedoresCreate" component={BonificacionesProveedoresCreate} />

                 {/* Reportes */}
                 <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/ReporteContenedores" component={ReporteContenedores} />
                <Route exact path ="/ReporteContenedoresCargos" component={ReporteContenedoresCargos} />
                <Route exact path ="/ReporteTransferencias" component={ReporteTransferencias} />
                <Route exact path ="/ReporteFacturas" component={ReporteFacturas} />
                <Route exact path ="/ReporteUtilidades" component={ReporteUtilidades} />

                {/* Whatsapp */}
                <Route exact path ="/Whatsapp" component={Whatsapp} />

                {/* Contenedores */}
                <Route exact path ="/MenuContenedores" component={BotonesContenedores} />
                <Route exact path ="/ContenedoresCreate" component={ContenedoresCreate} />
                <Route exact path ="/ListadoContenedores" component={ListadoContenedores} />
                <Route exact path ="/ListadoCargosContenedor" component={ListadoCargosContenedor} />
                 <Route exact path ="/ListadoTerminales" component={ListadoTerminales} />
                 <Route exact path ="/ListadoProgramas" component={ListadoProgramas} />
                 <Route exact path ="/ListadoPatentes" component={ListadoPatentes} />

                {/* Retiros */}
                <Route exact path ="/MenuRetiros" component={BotonesRetiros} />
                <Route exact path ="/Socios" component={ListadoSocios} />
                <Route exact path ="/Retiros" component={ListadoRetiros} />
                <Route exact path ="/RetirosCreate" component={RetirosCreate} />
                <Route exact path ="/PorRetirar" component={ListadoPorRetirar} />
                <Route exact path ="/PorRetirarCreate" component={PorRetirarCreate} />

            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
