import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportes() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuReportes ? (
      <div className="container">
          
            <Row>
            <Col md={4} align="center">
            {user.contenedoresReporte ?(
              <Button
                href="/ReporteContenedores"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Reporte Contenedores
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Reporte Contenedores
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.transferenciasReporte ?(
              <Button
                href="/ReporteTransferencias"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Reporte Transferencias
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Reporte Transferencias
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.facturasReporte ?(
              <Button
                href="/ReporteFacturas"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Reporte Facturas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Reporte Facturas
          </Button> }
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.contenedoresReporte ?(
              <Button
                href="/ReporteContenedoresCargos"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Reporte Contenedores
                <br />
                Con Cargos
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Reporte Contenedores
            <br />
            Con Cargos
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.contenedoresReporte ?(
              <Button
                href="/ReporteUtilidades"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Reporte Utilidad
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Reporte Utilidad
          </Button> }
            </Col>
            </Row>
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportes;
