import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function AnticiposCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ANTICIPOS = process.env.REACT_APP_URL_ANTICIPOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [bancos, setBancos] = useState([]);
  const [selectedBanco, setSelectedBanco] = useState([]);
  const [moneda, setMoneda] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [clientes, setClientes] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [importe, setImporte] = useState(0);
  const [formaPago, setFormaPago] = useState("");

  const [tipoComisionCliente, setTipoComisionCliente] = useState("NA");
  const [porcentajeComisionCliente, setPorcentajeComisionCliente] = useState(0);
  const [numeroComisionCliente, setNumeroComisionCliente] = useState(0);

  const [tipoComisionBanco, setTipoComisionBanco] = useState("NA");
  const [porcentajeComisionBanco, setPorcentajeComisionBanco] = useState(0);
  const [numeroComisionBanco, setNumeroComisionBanco] = useState(0);

  const [value, setValue] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveAnticipo = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_ANTICIPOS,
        {
          fecha,
          importe,
          formaPago,
          bancos: selectedBanco,
          clientes: value._id,
          utilizado: 0,
          saldo: parseFloat(importe) - parseFloat(numeroComisionCliente),

          tipoComisionCliente,
          porcentajeComisionCliente,
          numeroComisionCliente,

          tipoComisionBanco,
          porcentajeComisionBanco,
          numeroComisionBanco,

          observaciones,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data) => {
        axios.post(
          `${URL_MOVIMIENTOS_BANCOS}`,
          {
            fecha,
            importe: importe,
            tipo: "Ingreso",
            bancos: selectedBanco,
            anticipos: data.data._id,
            moneda,
            tipoCambio: 1,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        if (tipoComisionBanco != "NA") {
          axios.post(
            `${URL_MOVIMIENTOS_BANCOS}`,
            {
              fecha,
              importe: -numeroComisionBanco,
              tipo: "Egreso",
              bancos: selectedBanco,
              anticipos: data.data._id,
              moneda,
              tipoCambio: 1,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        }

        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Anticipo",
            detalle: `Fecha: ${fecha} / Cliente: ${value.name} / Importe: ${importe} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.anticiposCreate ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">Nuevo Anticipo</h3>
            <Form onSubmit={saveAnticipo}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>
                  <Col md={3}>
                    <Label className="mr-sm-2">Fecha</Label>
                    <Input
                      type="date"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label className="mr-sm-2">Cliente</Label>
                    <Autocomplete
                      size="small"
                      value={value}
                      onChange={(event, selectedCliente) => {
                        if (selectedCliente) {
                          setValue(selectedCliente);
                        }
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre_comercial}
                        </React.Fragment>
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <Label className="mr-sm-2">Forma de Pago</Label>
                    <Input
                      type="select"
                      value={formaPago}
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="">Selecciona una Forma de Pago</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Transfer">Transfer</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                  <Label>Observaciones</Label>
                    <Input
                      type="text"
                      value={observaciones}
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Label className="mr-sm-2">Importe</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={importe}
                      className={`form-control`}
                      onValueChange={(value, name) => {
                        setImporte(value);

                        if (tipoComisionCliente == "total") {
                          setNumeroComisionCliente(
                            value * (porcentajeComisionCliente / 100)
                          );
                        } else {
                          setNumeroComisionCliente(
                            (
                              (value / 1.16) *
                              (porcentajeComisionCliente / 100)
                            ).toFixed(2)
                          );
                        }

                        if (tipoComisionBanco == "Total") {
                          setNumeroComisionBanco(
                            value * (porcentajeComisionBanco / 100)
                          );
                        } else {
                          setNumeroComisionBanco(
                            (
                              (value / 1.16) *
                              (porcentajeComisionBanco / 100)
                            ).toFixed(2)
                          );
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Label className="mr-sm-2">Tipo de Comision Cliente</Label>
                    <Input
                      type="select"
                      value={tipoComisionCliente}
                      onChange={(e) => {
                        setTipoComisionCliente(e.target.value);
                        setPorcentajeComisionCliente(0);
                        setNumeroComisionCliente(0);
                      }}
                    >
                      <option value="NA">Selecciona</option>
                      <option value="Total">Total</option>
                      <option value="IVA">IVA</option>
                    </Input>
                  </Col>
                </Row>
                {tipoComisionCliente != "NA" ? (
                  <Row>
                    <Col md={6}>
                      <Label className="mr-sm-2">Comision Cliente %</Label>
                      <Input
                        type="number"
                        value={porcentajeComisionCliente}
                        onChange={(e) => {
                          setPorcentajeComisionCliente(e.target.value);

                          if (tipoComisionCliente == "Total") {
                            setNumeroComisionCliente(
                              importe * (e.target.value / 100)
                            );
                          } else {
                            setNumeroComisionCliente(
                              (
                                (importe / 1.16) *
                                (e.target.value / 100)
                              ).toFixed(2)
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Label className="mr-sm-2">Comision Cliente $</Label>
                      <Input
                        type="number"
                        value={numeroComisionCliente}
                        onChange={(e) => {
                          setNumeroComisionCliente(e.target.value);

                          if (tipoComisionCliente == "Total") {
                            setPorcentajeComisionCliente(
                              ((e.target.value / importe) * 100).toFixed(2)
                            );
                          } else {
                            setPorcentajeComisionCliente(
                              (
                                (e.target.value / (importe / 1.16)) *
                                100
                              ).toFixed(2)
                            );
                          }
                        }}
                      />
                    </Col>
                  </Row>
                ) : undefined}
                <hr />
                <Row>
                  <Col md={6}>
                    <Label className="mr-sm-2">Banco</Label>
                    <Input
                      type="select"
                      value={selectedBanco}
                      onChange={(e) => {
                        jalaBanco(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Banco</option>
                      {bancos
                        .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                        .map((a) => {
                          if (a.moneda == "MXN") {
                            return (
                              <option value={a._id}>
                                {a.banco} {a.cuenta}
                              </option>
                            );
                          }
                        })}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Label className="mr-sm-2">Tipo de Comision Banco</Label>
                    <Input
                      type="select"
                      value={tipoComisionBanco}
                      onChange={(e) => {
                        setTipoComisionBanco(e.target.value);
                        setPorcentajeComisionBanco(0);
                        setNumeroComisionBanco(0);
                      }}
                    >
                      <option value="NA">Selecciona</option>
                      <option value="Total">Total</option>
                      <option value="IVA">IVA</option>
                    </Input>
                  </Col>
                </Row>
                {tipoComisionBanco != "NA" ? (
                  <Row>
                    <Col md={6}>
                      <Label className="mr-sm-2">Comision Banco %</Label>
                      <Input
                        type="number"
                        value={porcentajeComisionBanco}
                        onChange={(e) => {
                          setPorcentajeComisionBanco(e.target.value);

                          if (tipoComisionBanco == "Total") {
                            setNumeroComisionBanco(
                              importe * (e.target.value / 100)
                            );
                          } else {
                            setNumeroComisionBanco(
                              (
                                (importe / 1.16) *
                                (e.target.value / 100)
                              ).toFixed(2)
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Label className="mr-sm-2">Comision Banco $</Label>
                      <Input
                        type="number"
                        value={numeroComisionBanco}
                        onChange={(e) => {
                          setNumeroComisionBanco(e.target.value);

                          if (tipoComisionBanco == "Total") {
                            setPorcentajeComisionBanco(
                              ((e.target.value / importe) * 100).toFixed(2)
                            );
                          } else {
                            setPorcentajeComisionBanco(
                              (
                                (e.target.value / (importe / 1.16)) *
                                100
                              ).toFixed(2)
                            );
                          }
                        }}
                      />
                    </Col>
                  </Row>
                ) : undefined}
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/ListadoAnticipos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default AnticiposCreate;
