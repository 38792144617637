import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesCXP() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menu_gastos ?(
      <div className="container">
        <br />
        <br />
        <Row >

      
          
         

            <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/Gastos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Gastos")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Gastos")}
            </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/GastosContenedor" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Gastos Contenedor")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Gastos Contenedor")}
            </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menuPagos ?(
            <Button href="/ListadoPagos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Pagos")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Pagos")}
            </Button> }
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.gastosCreate ?(
            <Button href="/ListadoAnticiposProveedores" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Anticipos")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Anticipos")}
            </Button> }
            </Col>

            <Col md={4} align="center">
            {user.pagosCreate ?(
            <Button href="/ListadoBonificacionesProveedores" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Bonificaciones")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Bonificaciones")}
            </Button> }
            </Col>

            <Col md={4} align="center">
            {user.estadoCuentaProveedores ?(
            <Button href="/EstadoCuentaCXP" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Estado de Cuenta")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Estado de Cuenta")}
            </Button> }
            </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCXP;
