import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function ReporteTransferencias() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);
  let inicio2 = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(inicio2);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_CARGOS}FechasComisiones/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            return {
              total: a.comisionNumero,
              cliente: a.clientes[0].nombre_comercial,
              idCliente: a.clientes[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

          let agrupado = arrayTabla.reduce(function (groups, item) {
            const val = item["idCliente"];
            groups[val] = groups[val] || {
              idCliente: item.idCliente,
              total: 0,
            };
            groups[val].total += item.total;
            groups[val].idCliente = item.idCliente;
            groups[val].cliente = item.cliente;
            return groups;
          }, []);

        let dataFinal = Object.values(agrupado);
        setComments(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [user, selectedFechaInicio, selectedFechaFin]);

  let totalTabla = 0;

  function PDFTabla() {
    let total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          roundingIncrement: 5,
        }).format(c.total);
        return [c.cliente, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Reporte de Transferencias", 20, 25);
    doc.autoTable({
      head: [["Cliente", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            roundingIncrement: 5,
          }).format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("ReporteTransferencias.pdf");
  }

  function excel() {
    const dataExcel = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        return {
          Cliente: c.cliente,
          Importe: c.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `ReporteTransferencias-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `ReporteTransferencias`,
        sheetFilter: ["Cliente", "Importe"],
        sheetHeader: ["Cliente", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          roundingIncrement: 5,
        }).format(c.total);
        return [c.cliente, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text("Reporte de Transferencias", 20, 25);
    doc.autoTable({
      head: [["Cliente", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            roundingIncrement: 5,
          }).format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Reporte de Transferencias",
          email: mailTo,
          fileName: "ReporteTransferencias.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Reporte de Transferencias.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  

  const headers = [
    { name: `${t("Cliente")}`, field: "cliente", sortable: true },
    { name: `${t("Importe")}`, field: "total", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.total.toString().includes(search) 
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "total") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && sorting.field == "total") {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && sorting.field == "total") {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
  ]);




  return (
    <>
      <Header />
      <br />
      <br />
      {user.transferenciasReporte ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                
                    <Button
                      size="sm"
                      href="/MenuReportes"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      {t("Regresar")}
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>{t(<Header />)}</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      {t("Enviar")}
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">{t("Reporte de Transferencias")}</h3>
                <Row>
                  <Col md={2}>
                    <Label>{t("Fecha Inicio")}</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>{t("Fecha Fin")}</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.total;
                      return (
                        <tr>
                          <td>{c.cliente}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              roundingIncrement: 5,
                            }).format(c.total)}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                          roundingIncrement: 5,
                        }).format(totalTabla)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ReporteTransferencias;
