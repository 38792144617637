import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col
} from "reactstrap";
import Header from "../Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable"
import useFullPageLoader from "../../hooks/useFullPageLoader"
import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ListadoUsuarios() {
  const { user } = useContext(AuthContext);
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_LISTA_USERS = process.env.REACT_APP_URL_LISTA_USERS;
  const [usuarios, setUsuarios] = useState([]);
  const [t, i18n] = useTranslation("global");
  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });
const [activo, setActivo] = useState("");
const ITEMS_PER_PAGE = 50

const [idEdit, setIdEdit] = useState();
const [nombre, setNombre] = useState();
const [apellido, setApellido] = useState();
const [email, setEmail] = useState();
const [is_active, setIsActive] = useState();
const [area, setArea] = useState();

const [idPermisos, setIdPermisos] = useState();

const [menu_usuarios, setMenuUsuarios] = useState();
const [usuarios_create, setUsuariosCreate] = useState();
const [usuarios_permisos, setUsuariosPermisos] = useState();

const [menu_logs, setMenuLogs] = useState();
const [menu_gastos, setMenuGastos] = useState();

const [menuClientes, setMenuClientes] = useState()
const [menuArticulos, setMenuArticulos] = useState()
const [menuNavieras, setMenuNavieras] = useState()
const [menuProveedores, setMenuProveedores] = useState()
const [menuEmpresas, setMenuEmpresas] = useState()
const [menuContenedores, setMenuContenedores] = useState()
const [contenedoresCreate, setContenedoresCreate] = useState()
const [contenedoresReporte, setContenedoresReporte] = useState()
const [contenedoresEntrega, setContenedoresEntrega] = useState()
const [menuVentas, setMenuVentas] = useState()
const [gastosCreate, setGastosCreate] = useState()
const [gastosPagar, setGastosPagar] = useState()
const [menuPagos, setMenuPagos] = useState()
const [pagosCreate, setPagosCreate] = useState()
const [estadoCuentaProveedores, setEstadoCuentaProveedores] = useState()
const [estadoCuentaClientes, setEstadoCuentaClientes] = useState()
const [menuAbonos, setMenuAbonos] = useState()
const [abonosCreate, setAbonosCreate] = useState()
const [menuCatalogos, setMenuCatalogos] = useState()
const [menuAdmin, setMenuAdmin] = useState()
const [menuReportes, setMenuReportes] = useState()


const [clientesCreate, setClientesCreate] = useState()
const [menuRetiros, setMenuRetiros] = useState()
const [retirosCreate, setRetirosCreate] = useState()
const [menuSocios, setMenuSocios] = useState()
const [menuCargos, setMenuCargos] = useState()
const [cargosCreate, setCargosCreate] = useState()
const [menuBancos, setMenuBancos] = useState()
const [menuAnticipos, setMenuAnticipos]  = useState()
const [anticiposCreate, setAnticiposCreate] = useState()
const [menuDevoluciones, setMenuDevoluciones] = useState()
const [devolucionesCreate, setDevolucionesCreate] = useState()
const [transferenciasReporte, setTransferenciasReporte] = useState()
const [facturasReporte, setFacturasReporte] = useState()

const [emailSis, setEmailSis] = useState("admin@sistemify.com");

const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);
const [text, setText] = useState(false);

const [modalPermisos, setModalPermisos] = useState(false);
const togglePermisos = () => setModalPermisos(!modalPermisos);

  useEffect(() => {
    axios
      .get(URL_LISTA_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        let arrayTabla = allUsuarios
        .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            nombre: a.nombre,
            apellido: a.apellido,
            email: a.email,
            is_active: a.is_active,
            menu_usuarios: a.menu_usuarios,
            usuarios_create: a.usuarios_create,
            usuarios_permisos: a.usuarios_permisos,
            menu_logs: a.menu_logs,
            menu_gastos: a.menu_gastos,
            menuClientes: a.menuClientes,
            menuArticulos: a.menuArticulos,
            menuNavieras: a.menuNavieras,
            menuProveedores: a.menuProveedores,
            menuEmpresas: a.menuEmpresas,
            menuContenedores: a.menuContenedores,
            contenedoresCreate: a.contenedoresCreate,
            contenedoresReporte: a.contenedoresReporte,
            contenedoresEntrega: a.contenedoresEntrega,
            menuVentas: a.menuVentas,
            gastosCreate: a.gastosCreate,
            gastosPagar: a.gastosPagar,
            menuPagos: a.menuPagos,
            pagosCreate: a.pagosCreate,
            estadoCuentaProveedores: a.estadoCuentaProveedores,
            estadoCuentaClientes: a.estadoCuentaClientes,
            menuAbonos: a.menuAbonos,
            abonosCreate: a.abonosCreate,
            menuCatalogos: a.menuCatalogos,
            menuAdmin: a.menuAdmin,
            menuReportes: a.menuReportes,

            clientesCreate: a.clientesCreate,
            menuRetiros: a.menuRetiros,
            retirosCreate: a.retirosCreate,
            menuSocios: a.menuSocios,
            menuCargos: a.menuCargos,
            cargosCreate: a.cargosCreate,
            menuBancos: a.menuBancos,
            menuAnticipos: a.menuAnticipos, 
            anticiposCreate: a.anticiposCreate,
            menuDevoluciones: a.menuDevoluciones,
            devolucionesCreate: a.devolucionesCreate,
            transferenciasReporte: a.transferenciasReporte,
            facturasReporte: a.facturasReporte,
          }
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setUsuarios(allUsuarios);
      })
      .catch((err) => {
        console.log(err);
      });

  },[]);

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Apellido", field: "apellido", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
                comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.email.toLowerCase().includes(search.toLowerCase())
        );
    }


    if(activo){
      computedComments = computedComments.filter((e)=>
      e.is_active.includes(activo)
      ) 
    } 

    if(emailSis){
      computedComments = computedComments.filter((e)=>
      !e.email.includes(emailSis)
      ) 
    }     

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, activo]);

function Edit(idEdit, nombre, apellido, email){
  setIdEdit(idEdit)
  setNombre(nombre)
  setApellido(apellido)
  setEmail(email)
  toggle()
}

function editUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idEdit}`,
      {
        nombre,
        apellido,
        email,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function Permisos (
  id, 
  nombre,
  apellido,
  menu_usuarios,
  usuarios_create,
  usuarios_permisos,
  menu_logs,
  menu_gastos,
  menuClientes,
  menuArticulos,
  menuNavieras,
  menuProveedores,
  menuEmpresas,
  menuContenedores,
  contenedoresCreate,
  contenedoresReporte,
  contenedoresEntrega,
  menuVentas,
  gastosCreate,
  gastosPagar,
  menuPagos,
  pagosCreate,
  estadoCuentaProveedores,
  estadoCuentaClientes,
  menuAbonos,
  abonosCreate,
  menuCatalogos,
  menuAdmin,
  menuReportes,
  
  clientesCreate,
  menuRetiros,
  retirosCreate,
  menuSocios,
  menuCargos,
  cargosCreate,
  menuBancos,
  menuAnticipos, 
  anticiposCreate,
  menuDevoluciones,
  devolucionesCreate,
  transferenciasReporte,
  facturasReporte,
  ){
      setIdPermisos(id)
      setNombre(nombre)
      setApellido(apellido)
      setMenuUsuarios(menu_usuarios)
      setUsuariosCreate(usuarios_create)
      setUsuariosPermisos(usuarios_permisos)
      setMenuLogs(menu_logs)
      setMenuGastos(menu_gastos)
      setMenuClientes(menuClientes)
      setMenuArticulos(menuArticulos)
      setMenuNavieras(menuNavieras)
      setMenuProveedores(menuProveedores)
      setMenuEmpresas(menuEmpresas)
      setMenuContenedores(menuContenedores)
      setContenedoresCreate(contenedoresCreate)
      setContenedoresReporte(contenedoresReporte)
      setContenedoresEntrega(contenedoresEntrega)
      setMenuVentas(menuVentas)
      setGastosCreate(gastosCreate)
      setGastosPagar(gastosPagar)
      setMenuPagos(menuPagos)
      setPagosCreate(pagosCreate)
      setEstadoCuentaProveedores(estadoCuentaProveedores)
      setEstadoCuentaClientes(estadoCuentaClientes)
      setMenuAbonos(menuAbonos)
      setAbonosCreate(abonosCreate)
      setMenuCatalogos(menuCatalogos)
      setMenuAdmin(menuAdmin)
      setMenuReportes(menuReportes)
      
      setClientesCreate(clientesCreate)
      setMenuRetiros(menuRetiros)
      setRetirosCreate(retirosCreate)
      setMenuSocios(menuSocios)
      setMenuCargos(menuCargos)
      setCargosCreate(cargosCreate)
      setMenuBancos(menuBancos)
      setMenuAnticipos(menuAnticipos)
      setAnticiposCreate(anticiposCreate)
      setMenuDevoluciones(menuDevoluciones)
      setDevolucionesCreate(devolucionesCreate)
      setTransferenciasReporte(transferenciasReporte)
      setFacturasReporte(facturasReporte)
      togglePermisos()
}

function permisosUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idPermisos}`,
      {
        menu_usuarios,
        usuarios_create,
        usuarios_permisos,
        menu_logs,
        menu_gastos,
        menuClientes,
        menuArticulos,
        menuNavieras,
        menuProveedores,
        menuEmpresas,
        menuContenedores,
        contenedoresCreate,
        contenedoresReporte,
        contenedoresEntrega,
        menuVentas,
        gastosCreate,
        gastosPagar,
        menuPagos,
        pagosCreate,
        estadoCuentaProveedores,
        estadoCuentaClientes,
        menuAbonos,
        abonosCreate,
        menuCatalogos,
        menuAdmin,
        menuReportes,
        
        clientesCreate,
        menuRetiros,
        retirosCreate,
        menuSocios,
        menuCargos,
        cargosCreate,
        menuBancos,
        menuAnticipos, 
        anticiposCreate,
        menuDevoluciones,
        devolucionesCreate,
        transferenciasReporte,
        facturasReporte,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      togglePermisos();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

  return (
    <>
    <Header />
    <br />
    {user.menu_usuarios  ?(
        <div className="row">
        {
          <div className="col-8">
            <div className="card container">
              <div className="card-body">
                <h3 align="center">Usuarios</h3>
                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                    <tbody>
                    {commentsData
                    .map((p) => {
                        // if (p.menu_edoCta_Fam == false && p.menu_edoCta_Don == false && p.email != "admin@sistemify.com") {
                      return (
                        <tr>
                          <td>{p.is_active}</td>
                          <td>{p.nombre}</td>
                          <td>{p.apellido}</td>
                          <td>{p.email}</td>
                          <td>
                          {user.usuarios_create?(
                          <Button color="info" id="Editar" size="sm" onClick={e=>{Edit(p._id, p.nombre, p.apellido, p.email)}}>
                            <i class="far fa-edit"></i>
                          </Button>
                        ):(
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="far fa-edit"></i>
                          </Button>
                        )}
                            {user.usuarios_permisos  && user.is_active =="Si" ?(
                            <Button color="success" id="Permisos" size="sm" onClick={e=>{Permisos(
                              p._id, 
                              p.nombre,
                              p.apellido,
                              p.menu_usuarios,
                              p.usuarios_create,
                              p.usuarios_permisos,
                              p.menu_logs,
                              p.menu_gastos,
                              p.menuClientes,
                              p.menuArticulos,
                              p.menuNavieras,
                              p.menuProveedores,
                              p.menuEmpresas,
                              p.menuContenedores,
                              p.contenedoresCreate,
                              p.contenedoresReporte,
                              p.contenedoresEntrega,
                              p.menuVentas,
                              p.gastosCreate,
                              p.gastosPagar,
                              p.menuPagos,
                              p.pagosCreate,
                              p.estadoCuentaProveedores,
                              p.estadoCuentaClientes,
                              p.menuAbonos,
                              p.abonosCreate,
                              p.menuCatalogos,
                              p.menuAdmin,
                              p.menuReportes,
                              
                              p.clientesCreate,
                              p.menuRetiros,
                              p.retirosCreate,
                              p.menuSocios,
                              p.menuCargos,
                              p.cargosCreate,
                              p.menuBancos,
                              p.menuAnticipos, 
                              p.anticiposCreate,
                              p.menuDevoluciones,
                              p.devolucionesCreate,
                              p.transferenciasReporte,
                              p.facturasReporte,
                              )}}>
                            <i class="fas fa-key"></i>{" "}
                          </Button>
                            ):(
                              <Button color="success" id="Permisos" size="sm" disabled>
                            <i class="fas fa-key"></i>{" "}
                          </Button>
                            )}
                            {user.usuarios_create?(
                          <Baja
                            idStatus={p._id}
                            is_active={p.is_active}
                            URL_BAJA={process.env.REACT_APP_URL_USERS}
                          />
                            ):undefined}
                          </td>
                        </tr>
                      );
                      // }
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        }
 <div className="col-4">
          <div className="card">
            <div className="card-body">
              <Signup />
            </div>
          </div>
        </div>
        </div>
      ): undefined } 

<Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Editar Usuario {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">{t("Nombre")}</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombre}
            required
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Apellido</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={apellido}
            required
            onChange={(e) => {
              setApellido(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={email}
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editUsuario}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* //Permisos */}
      <Modal size="xl" isOpen={modalPermisos} toggle={togglePermisos}>
        <ModalHeader toggle={togglePermisos}><h4>Permisos de {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
        <div className="container">

          <Row>
          <Col md={3}>
      <h5>CATALOGOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuCatalogos(event.currentTarget.checked)}
        checked={menuCatalogos}
      /><h5 className="subMenuTitulo">Menu Catalogos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuClientes(event.currentTarget.checked)}
        checked={menuClientes}
      /><h5 className="subMenuTitulo">Clientes</h5>
      <Input
      type="checkbox"
      onChange={(event) => setClientesCreate(event.currentTarget.checked)}
      checked={clientesCreate}
    /><h5 className="subMenuTitulo">Crear Clientes</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuArticulos(event.currentTarget.checked)}
      checked={menuArticulos}
    /><h5 className="subMenuTitulo">Articulos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuNavieras(event.currentTarget.checked)}
        checked={menuNavieras}
      /><h5 className="subMenuTitulo">Navieras</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuProveedores(event.currentTarget.checked)}
      checked={menuProveedores}
     /><h5 className="subMenuTitulo">Proveedores</h5>
          <Input
          type="checkbox"
          onChange={(event) => setMenuEmpresas(event.currentTarget.checked)}
          checked={menuEmpresas}
        /><h5 className="subMenuTitulo">Empresas</h5>

      </Col>

      <Col md={3}>
      <h5>CONTENEDORES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuContenedores(event.currentTarget.checked)}
        checked={menuContenedores}
      /><h5 className="subMenuTitulo">Menu Contenedores</h5>
        <Input
        type="checkbox"
        onChange={(event) => setContenedoresCreate(event.currentTarget.checked)}
        checked={contenedoresCreate}
      /><h5 className="subMenuTitulo">Crear Contenedores</h5>
       <Input
        type="checkbox"
        onChange={(event) => setContenedoresEntrega(event.currentTarget.checked)}
        checked={contenedoresEntrega}
      /><h5 className="subMenuTitulo">Entrega de Contenedores</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuVentas(event.currentTarget.checked)}
      checked={menuVentas}
     /><h5 className="subMenuTitulo">Ventas</h5>
      </Col>

      <Col md={3}>
      <h5>CARGOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuCargos(event.currentTarget.checked)}
        checked={menuCargos}
      /><h5 className="subMenuTitulo">Menu Cargos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setCargosCreate(event.currentTarget.checked)}
        checked={cargosCreate}
      /><h5 className="subMenuTitulo">Crear Cargos</h5></Col>

      <Col md={3}>
      <h5>ADMIN</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuAdmin(event.currentTarget.checked)}
        checked={menuAdmin}
      /><h5 className="subMenuTitulo">Menu Admin</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuAbonos(event.currentTarget.checked)}
      checked={menuAbonos}
    /><h5 className="subMenuTitulo">Menu Abonos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setAbonosCreate(event.currentTarget.checked)}
        checked={abonosCreate}
      /><h5 className="subMenuTitulo">Crear Abonos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuBancos(event.currentTarget.checked)}
      checked={menuBancos}
    /><h5 className="subMenuTitulo">Bancos</h5>
    <Input
    type="checkbox"
    onChange={(event) => setMenuAnticipos(event.currentTarget.checked)}
    checked={menuAnticipos}
  /><h5 className="subMenuTitulo">Menu Anticipos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setAnticiposCreate(event.currentTarget.checked)}
      checked={anticiposCreate}
    /><h5 className="subMenuTitulo">Crear Anticipos</h5>
    <Input
    type="checkbox"
    onChange={(event) => setMenuDevoluciones(event.currentTarget.checked)}
    checked={menuDevoluciones}
  /><h5 className="subMenuTitulo">Menu Devoluciones</h5>
      <Input
      type="checkbox"
      onChange={(event) => setDevolucionesCreate(event.currentTarget.checked)}
      checked={devolucionesCreate}
    /><h5 className="subMenuTitulo">Crear Devoluciones</h5>
      <Input
      type="checkbox"
      onChange={(event) => setEstadoCuentaClientes(event.currentTarget.checked)}
      checked={estadoCuentaClientes}
     /><h5 className="subMenuTitulo">Estado Cuenta Clientes</h5>
      </Col>
          </Row>

        <br />
        <Row>
          
        <Col md={3}>
              <h5>RETIROS</h5>
                <Input
                type="checkbox"
                onChange={(event) => setMenuRetiros(event.currentTarget.checked)}
                checked={menuRetiros}
              /><h5 className="subMenuTitulo">Menu Retiros</h5>
                <Input
                type="checkbox"
                onChange={(event) => setRetirosCreate(event.currentTarget.checked)}
                checked={retirosCreate}
              /><h5 className="subMenuTitulo">Crear Retiros</h5>
              <Input
              type="checkbox"
              onChange={(event) => setMenuSocios(event.currentTarget.checked)}
              checked={menuSocios}
            /><h5 className="subMenuTitulo">Socios</h5>
              </Col>

              
              <Col md={3}>
      <h5>GASTOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuGastos(event.currentTarget.checked)}
        checked={menu_gastos}
      /><h5 className="subMenuTitulo">Menu Gastos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setGastosCreate(event.currentTarget.checked)}
        checked={gastosCreate}
      /><h5 className="subMenuTitulo">Crear Gastos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setGastosPagar(event.currentTarget.checked)}
      checked={gastosPagar}
    /><h5 className="subMenuTitulo">Pagar Gastos</h5>
      </Col>

      <Col md={3}>
      <h5>PAGOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuPagos(event.currentTarget.checked)}
        checked={menuPagos}
      /><h5 className="subMenuTitulo">Menu Pagos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setPagosCreate(event.currentTarget.checked)}
        checked={pagosCreate}
      /><h5 className="subMenuTitulo">Crear Pagos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setEstadoCuentaProveedores(event.currentTarget.checked)}
      checked={estadoCuentaProveedores}
     /><h5 className="subMenuTitulo">Estado Cuenta Proveedores</h5>
      </Col>
        {/* </Row>
          <br />
          
          <Row> */}

         

    <Col md={3}>
    <h5>REPORTES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuReportes(event.currentTarget.checked)}
        checked={menuReportes}
      /><h5 className="subMenuTitulo">Menu Reportes</h5>
      <Input
      type="checkbox"
      onChange={(event) => setContenedoresReporte(event.currentTarget.checked)}
      checked={contenedoresReporte}
    /><h5 className="subMenuTitulo">Reporte Contenedores</h5>
      
      <Input
      type="checkbox"
      onChange={(event) => setTransferenciasReporte(event.currentTarget.checked)}
      checked={transferenciasReporte}
    /><h5 className="subMenuTitulo">Reporte Transferencias</h5>
      
      
      <Input
      type="checkbox"
      onChange={(event) => setFacturasReporte(event.currentTarget.checked)}
      checked={facturasReporte}
    /><h5 className="subMenuTitulo">Reporte Facturas</h5>
      
      </Col>

      <Col md={3}>
    <h5>USUARIOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuUsuarios(event.currentTarget.checked)}
        checked={menu_usuarios}
      /><h5 className="subMenuTitulo">Menu Usuarios</h5>
      
      </Col>

      <Col md={3}>
      <h5>LOGS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuLogs(event.currentTarget.checked)}
        checked={menu_logs}
      /><h5 className="subMenuTitulo">Menu Logs</h5>
      </Col>
      


      </Row>
      </div>
        </ModalBody>
        <ModalFooter>
          <div className="container">
          <Button color="success" 
          onClick={permisosUsuario}
          >
            {t("Guardar")}
          </Button>
          </div>
        </ModalFooter>
      </Modal>

{loader}
    </>
  );
}

export default ListadoUsuarios;
