import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import moment from 'moment';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_CARGOS_CONTENEDOR = process.env.REACT_APP_URL_CARGOS_CONTENEDOR;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  
  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [fecha, setFecha] = useState(hoy);


  const [formaPago, setFormaPago] = useState("Efectivo");
  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("Abono");
  const [total, setTotal] = useState(0);


  const [inputFields, setInputFields] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);



  useMemo(()=>{
    axios
    .get(URL_CLIENTES, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allClientes = response.data;
      setClientes(allClientes);
    })
    .catch((err) => {
      console.log(err);
    });

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

if(selectedCliente){
    axios
    .get(`${URL_CARGOS_CONTENEDOR}ClientePorPagar/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allCargosContenedor = response.data
      let arrayTabla = allCargosContenedor
        .map((a) => {
          return {
            id: a._id,
            idContenedor: a.contenedores[0]._id,
            fecha: a.fecha,
            importeOriginal: a.total,
            total_general: a.total,
            comision: 0,
            saldo: a.saldo,
            abonos: a.abonos,
            nota: a.idCargoContenedor,
            importe: a.saldo,
            clientes: a.clientes[0]._id,
            observaciones: "NA",
            select:false,
            tipo:"cargoContenedor"
          };
        }).filter(function (el) {
          return el != null;
        });
        let dataFinalCC = Object.values(arrayTabla);

        axios
        .get(`${URL_CARGOS}ClientePorPagar/${selectedCliente}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allCargos = response.data
          let arrayTabla = allCargos
            .map((a) => {
              return {
                id: a._id,
                fecha: a.fecha,
                importeOriginal: a.total,
                total_general: a.total,
                comision: a.comisionNumero,
                saldo: a.saldo,
                abonos: a.abonos,
                nota: a.idCargo,
                importe: a.saldo,
                clientes: a.clientes[0]._id,
                observaciones: "NA",
                select:false,
                tipo:"cargo"
              };
            }).filter(function (el) {
              return el != null;
            });
            let dataFinalC = Object.values(arrayTabla);

            let dF = dataFinalCC.concat(dataFinalC);
            setInputFields(dF);
        })
        .catch((err) => {
          console.log(err);
        })
        
    })
    .catch((err) => {
      console.log(err);
    })}


  }, [selectedCliente]);


  const handleChangeInputImporte = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i[name] = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };



function ActualizaTotales(){
  let activos = inputFields.filter((e) => e.select == true);
  let TEfe = activos.map((c) => parseFloat(c.importe));
  let TE = TEfe.reduce((t, total, index) => t + total, 0);

  setTotal(TE )
}  



function savePago() {
  if(selectedBanco == ""){
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Debe seleccionar un Banco",
    });
    return
  }
  Swal.fire({
    title: "Estas seguro?",
    text: "Se registrará el abono",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Enviar!",
  }).then((result) => {
    if (result.isConfirmed) {
      toggleProgreso();
      let totalMails = inputFields.length 
      inputFields.map((a) => {
        if (a.select == true && a.importe > 0) {
          if(a.tipo == "cargoContenedor"){
            
          axios
            .post(
              URL_ABONOS,
              {
                fecha,
                importe: a.importe,
                clientes: selectedCliente,
                cargosContenedor: a.id,
                formaPago,
                observaciones: a.observaciones,
                bancos: selectedBanco,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then((dataAbono) => {

              axios
              .patch(
                `${URL_CARGOS_CONTENEDOR}Pagar/${a.id}`,
                {
                  clientes: selectedCliente,
                  contenedores: a.idContenedor,
                  total: a.total_general - a.importeOriginal,
                  saldo: a.total_general - a.abonos - a.importe,
                  abonos: parseFloat(a.abonos) + parseFloat( a.importe)
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              ).then(() => {
                axios
                .post(
                  `${URL_MOVIMIENTOS_BANCOS}`,
                  {
                    fecha,
                    importe: a.importe,
                    tipo:"Ingreso",
                    bancos: selectedBanco,
                    abonos:dataAbono.data._id,
                    moneda,
                    tipoCambio:1
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
  
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  // footer: `${error.response.data}`,
                });
                console.log(error);
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                // footer: `${error.response.data}`,
              });
              console.log(error);
            });

          }else{
            
            axios
              .post(
                URL_ABONOS,
                {
                  fecha,
                  importe: a.importe,
                  clientes: selectedCliente,
                  cargos: a.id,
                  formaPago,
                  observaciones: a.observaciones,
                  bancos: selectedBanco,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((dataAbono) => {
  
                axios
                .patch(
                  `${URL_CARGOS}Pagar/${a.id}`,
                  {
                    clientes: selectedCliente,
                    total: a.total_general - a.importeOriginal,
                    saldo: a.total_general - a.abonos - a.importe,
                    abonos: parseFloat(a.abonos) + parseFloat( a.importe)
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                ).then(() => {
                  axios
                  .post(
                    `${URL_MOVIMIENTOS_BANCOS}`,
                    {
                      fecha,
                      importe: a.importe,
                      tipo:"Ingreso",
                      bancos: selectedBanco,
                      abonos:dataAbono.data._id,
                      moneda,
                      tipoCambio:1
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
    
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  // footer: `${error.response.data}`,
                });
                console.log(error);
              });}
        } else {
          totalMails = totalMails - 1;
          if (totalMails == 0) {
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })


    }
  });
}

const handleChangeInput = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
    }
    return i;
  });
  setInputFields(newInputFields);
};


const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosCreate  ?(
        <div className="card container col-sm-11">
          <h3 align="center">{t("Captura un Pago")}</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
              <Col md={2}>
                  <Label>{t("Fecha")}</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente)
                      setSelectedCliente(selectedCliente._id)
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Selecciona")}
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                    <Label className="mr-sm-2">{t("Concepto")}</Label>
                    <Input
                      type="select"
                      value={concepto}
                      onChange={(e) => {
                        setConcepto(e.target.value);
                      }}
                    >
                      <option value="">{t("Selecciona un Concepto")}</option>
                      <option value="Abono">{t("Abono")}</option>
                      <option value="Bonificacion">{t("Bonificacion")}</option>
                    </Input>
                  </Col>

                <Col md={2}>
                    <Label className="mr-sm-2">{t("Forma de Pago")}</Label>
                    <Input
                      type="select"
                      value={formaPago}
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="">{t("Selecciona una Forma de Pago")}</option>
                      <option value="Efectivo">{t("Efectivo")}</option>
                      <option value="Transfer">{t("Transfer")}</option>
                      <option value="Bonificacion">{t("Bonificacion")}</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        if(a.moneda =="MXN"){
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );}
                      })}
                  </Input>
                </Col>
                
               
            
                <Col md={2}>
                <h4 align="right">
                              {t("Total Pago")}{"  "}
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                              </Badge>
                            </h4>
                </Col>
              </Row>
             
              <br />
             <Row>
                {/* Tabla CargosContenedor */}
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                    <th></th>
                  <th>{t("Fecha")}</th>
                  <th>{t("Nota")}</th>
                  <th>{t("Total MXN")}</th>
                  <th>{t("Comision")}</th>
                  <th>{t("Abonos")}</th>
                  <th>{(t("Saldo"))}</th>
                  <th>{t("Importe")}</th>
                  <th>{t("Observaciones")}</th>
                  </tr>
                </thead>
                <tbody>
                  {inputFields.map((a) => (
                      // <div key={a.id}>
                            <tr>
                        <th>
                        <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setInputFields(
                                  inputFields.map(data => {
                                    if(a.id == data.id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                ActualizaTotales()
                              }}
                              checked={a.select}
                            />
                        </th>
                            <td>{a.fecha}</td>
                            <td>{a.nota}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.comision)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos)}</td>
                            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}</td>
                            <td>
                          { a.select?(
                              <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              onValueChange={(value, name) =>
                                handleChangeInputImporte(value, name, a.id)
                              }
                            />
                             ):(
                          <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />
                             )}

                        </td>
                            <td>
                              <Input
                              // bsSize="sm"
                                type="text" 
                                name="observaciones"
                                value={a.observaciones}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInput(a.id, e)
                                }}/>
                            </td>
                            </tr>
                          // </div>
                  ))}

                </tbody>
                <br />
              </Table>
          

            {/* Termina tabla CargosContenedor */}

             </Row>
            </FormGroup>
            <br />
            <Row>
            <Button
              className="btn btn-success"
              onClick={savePago}
            > {t("Registrar")}
            </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                 {t("Regresar")}
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
                  <ModalHeader toggle={toggleProgreso}>
                    <h4>{t("Progreso")}</h4>
                  </ModalHeader>
                  <ModalBody>
                    Estamos enviando los correos, este proceso puede tardar
                    varios minutos.
                    <br />
                    {t("Por favor no cierre ni refresque su navegador.")}
                    <br />
                    <div className="divPadre2">
                      <div className="divHijo2">
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

               
        </div>
      ): undefined } 
      
    </>
  );
}

export default AbonosCreate;
