import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ClientesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  // const [text, setText] = useState(false);
  const [t, i18n] = useTranslation("global");

  const [nombre_comercial, setNombreComercial] = useState("NA");
  const [razon_social, setRazonSocial] = useState("NA");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [contacto, setContacto] = useState("NA");
  const [telefono, setTelefono] = useState("0");
  const [email, setEmail] = useState("a@a.c");
  const [email2, setEmail2] = useState("a@a.c");
  const [email3, setEmail3] = useState("a@a.c");
  const [observaciones, setObservaciones] = useState("NA");
  const [validaBoton, setValidaBoton] = useState(true);



  const saveClientes = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_CLIENTES,
          {
            nombre_comercial,
            razon_social,
            RFC,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            contacto,
            telefono,
            email,
            email2,
            email3,
            observaciones,
            ultimo_pago: "2022-01-01",
            cargos: 0,
            abonos: 0,
            saldo: 0,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuClientes ? (
        <div className="card container">
          <h3 align="center">{t("Nuevo Cliente")}</h3>
          <Form onSubmit={saveClientes}>
            <Row>
              <Col md={3}>
                <Label>{t("Contacto")}</Label>
                <Input
                  type="text"
                  value={contacto}
                  required
                  onChange={(e) => {
                    setContacto(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Nombre Comercial")}</Label>
                <Input
                  type="text"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Razon Social")}</Label>
                <Input
                  type="text"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("RFC")}</Label>
                <Input
                  type="text"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Label>{t("Calle")}</Label>
                <Input
                  type="text"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>{t("No. Ext")}</Label>
                <Input
                  type="text"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>{t("No. Int")}</Label>
                <Input
                  type="text"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>{t("Pais")}</Label>
                <Input
                  type="text"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>{t("Estado")}</Label>
                <Input
                  type="text"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Ciudad")}</Label>
                <Input
                  type="text"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Delegacion")}</Label>
                <Input
                  type="text"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Colonia")}</Label>
                <Input
                  type="text"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
             
              <Col md={3}>
                <Label>{t("CP")}</Label>
                <Input
                  type="text"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Telefono")}</Label>
                <Input
                  type="text"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Email 2</Label>
                <Input
                  type="email"
                  value={email2}
                  required
                  onChange={(e) => {
                    setEmail2(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>

              <Col md={3}>
                <Label>Email 3</Label>
                <Input
                  type="email"
                  value={email3}
                  required
                  onChange={(e) => {
                    setEmail3(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label className="mr-sm-2">
                {t("Observaciones")}
                </Label>
                <Input
                  type="text"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
           

            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                {t("Guardar")}
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                {t("Guardar")}
              </Button>
            )}
            <Button
              href="/ListadoClientes"
              className="btn btn-danger"
              id="botonListado"
            >
               {t("Regresar")}
            </Button>
          </Form>
          <br />
        </div>
       ) : undefined }
    </>
  );
}


export default ClientesCreate;
