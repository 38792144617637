import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesCatalogos() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menuCatalogos ?(
      <div className="container">
        <br />
        <br />
        <Row >

        <Col md={4} align="center">
          {user.menuClientes ?(
            <Button href="/ListadoClientes" className="botonesMenu" color="success">
              <i class="fas fa-user-tie fa-7x"></i>
              <br />
              <br />
              {t("Clientes")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-user-tie fa-7x"></i>
          <br />
          <br />
          {t("Clientes")}
        </Button> }
          </Col>
          
          <Col md={4} align="center">
          {user.menuArticulos ?(
            <Button href="/ListadoArticulos" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              {t("Articulos")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tshirt fa-7x"></i>
          <br />
          <br />
          {t("Articulos")}
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menuProveedores ?(
            <Button href="/ListadoProveedores" className="botonesMenu" color="success">
              <i class="fas fa-hands-helping fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-hands-helping fa-7x"></i>
          <br />
          <br />
          Proveedores
        </Button> }
          </Col>

          </Row>
        <br />
        <Row>
          
          <Col md={4} align="center">
          {user.menuNavieras ?(
            <Button href="/ListadoNavieras" className="botonesMenu" color="success">
              <i class="fas fa-ship fa-7x"></i>
              <br />
              <br />
              {t("Navieras")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-ship fa-7x"></i>
          <br />
          <br />
          {t("Navieras")}
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menuProveedores ?(
            <Button href="/ListadoAgentes" className="botonesMenu" color="success">
              <i class="fas fa-user fa-7x"></i>
              <br />
              <br />
              Agentes
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-user fa-7x"></i>
          <br />
          <br />
          Agentes
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menuProveedores ?(
            <Button href="/ListadoForwarders" className="botonesMenu" color="success">
              <i class="fas fa-users fa-7x"></i>
              <br />
              <br />
              Forwarders
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-users fa-7x"></i>
          <br />
          <br />
          Forwarders
        </Button> }
          </Col>
        </Row>
        <br />
        <Row>
            <Col md={4} align="center">
          {user.menuEmpresas ?(
            <Button href="/ListadoEmpresas" className="botonesMenu" color="success">
              <i class="fas fa-building fa-7x"></i>
              <br />
              <br />
              {t("Empresas")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-building fa-7x"></i>
          <br />
          <br />
          {t("Empresas")}
        </Button> }
          </Col>
         
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCatalogos;
