import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesAdmin() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menuAdmin ?(
      <div className="container">
        <br />
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menuContenedores ?(
            <Button href="/ListadoContenedoresAdmin" className="botonesMenu" color="success">
              <i class="fas fa-box fa-7x"></i>
              <br />
              <br />
              {t("Contenedores")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-box fa-7x"></i>
          <br />
          <br />
          {t("Contenedores")}
        </Button> }
          </Col>
       
        <Col md={4} align="center">
          {user.menuCargos ?(
            <Button href="/Cargos" className="botonesMenu" color="success">
              <i class="fas fa-plus fa-7x"></i>
              <br />
              <br />
              {t("Cargos ")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plus fa-7x"></i>
          <br />
          <br />
          {t("Cargos ")}
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menuCargos ?(
            <Button href="/ListadoCargosContenedor" className="botonesMenu" color="success">
              <i class="fas fa-plus fa-7x"></i>
              <br />
              <br />
              {t("Cargos Contenedor")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plus fa-7x"></i>
          <br />
          <br />
          {t("Cargos Contenedor")}
        </Button> }
          </Col>

          </Row>
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menuAbonos ?(
            <Button href="/ListadoAbonos" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              {t("Abonos")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          {t("Abonos")}
        </Button> }
          </Col>


          
          <Col md={4} align="center">
          {user.estadoCuentaClientes ?(
            <Button href="/EstadoCuenta" className="botonesMenu" color="success">
              <i class="fas fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Estado de Cuenta")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-alt fa-7x"></i>
          <br />
          <br />
          {t("Estado de Cuenta")}
        </Button> }
          </Col>

        
        <Col md={4} align="center">
          {user.menuBancos ?(
            <Button href="/Bancos" className="botonesMenu" color="success">
              <i class="fas fa-university fa-7x"></i>
              <br />
              <br />
              Bancos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-university fa-7x"></i>
          <br />
          <br />
          Bancos
        </Button> }
          </Col>

          
          </Row>
        <br />
        <Row>
        <Col md={4} align="center">
            {user.menuAnticipos ?(
            <Button href="/ListadoAnticipos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Anticipos")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Anticipos")}
            </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menuDevoluciones ?(
            <Button href="/ListadoDevoluciones" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Devoluciones")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Devoluciones")}
            </Button> }
            </Col>

        <Col md={4} align="center">
          {user.menuBancos ?(
            <Button href="/MovimientosBancos" className="botonesMenu" color="success">
              <i class="fas fa-list-ol fa-7x"></i>
              <br />
              <br />
              Movimientos Bancos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-ol fa-7x"></i>
          <br />
          <br />
          Movimientos Bancos
        </Button> }
          </Col>
        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
            {user.menuDevoluciones ?(
            <Button href="/ListadoDevolucionesInversa" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Devoluciones Inversa")}
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Devoluciones Inversa")}
            </Button> }
            </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesAdmin;
