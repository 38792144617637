import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";

function ReporteContenedoresCargos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_CARGOS_CONTENEDOR = process.env.REACT_APP_URL_CARGOS_CONTENEDOR;
  const URL_GASTOS_CONTENEDOR = process.env.REACT_APP_URL_GASTOS_CONTENEDOR;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [t, i18n] = useTranslation("global");
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-91);

    let comp30 = new Date();
  comp30.setDate(comp30.getDate() -30 );
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");


  const [proveedores, setProveedores] = useState([]);
  const [selectedNaviera, setSelectedNaviera] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  
  const [totalUtilidad, setTotalUtilidad] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  const [modalDesgloce, setModalDesgloce] = useState(false);
  const toggleDesgloce = () => setModalDesgloce(!modalDesgloce);
  const [contenedor, setContenedor] = useState("");
  const [cargos, setCargos] = useState([]);
  const [gastos, setGastos] = useState([]);
  const [totalCargos, setTotalCargos] = useState(0);
  const [totalGastos, setTotalGastos] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_CARGOS_CONTENEDOR}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allContenedores = response.data;
        // Array para pagination
        let arrayTabla = allContenedores
          .sort((a, b) => (a.idCargoContenedor < b.idCargoContenedor ? 1 : -1))
          .map((a) => {
            return {
              _id: a.contenedores[0]._id,
              numero: a.contenedores[0].idContenedor,
              activo: a.contenedores[0].is_active,
              fecha: a.contenedores[0].fecha,
              observaciones: a.contenedores[0].observaciones,
              totalGeneral: a.contenedores[0].totalGeneral,
              navieras: a.contenedores[0].navieras[0].name,
              idNavieras: a.contenedores[0].navieras[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              totalCantidad: a.contenedores[0].totalCantidad,
              numeroInterno: a.clientes[0].nombre_comercial + "-" + a.contenedores[0].idContenedor,
              contenedor: a.contenedores[0].contenedor,
              etaReal: a.contenedores[0].etaReal,
              bl: a.contenedores[0].bl,
              pl: a.contenedores[0].pl,
              totalGastos: a.contenedores[0].totalGastos,
              aduana: a.contenedores[0].aduana,
              totalCargos: a.contenedores[0].totalCargos,
              totalUtilidad: a.contenedores[0].totalCargos - a.contenedores[0].totalGastos,
              saldo: a.contenedores[0].saldo,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["_id"];
          groups[val] = groups[val] || {
            _id: item._id,
          };
          groups[val]._id = item._id;
          groups[val].numero = item.numero
          groups[val].activo = item.activo
          groups[val].fecha = item.fecha
          groups[val].observaciones = item.observaciones
          groups[val].totalGeneral = item.totalGeneral
          groups[val].navieras = item.navieras
          groups[val].idNavieras = item.idNavieras
          groups[val].clientes = item.clientes
          groups[val].idClientes = item.idClientes
          groups[val].totalCantidad = item.totalCantidad
          groups[val].numeroInterno = item.numeroInterno
          groups[val].contenedor = item.contenedor
          groups[val].etaReal = item.etaReal
          groups[val].bl = item.bl
          groups[val].pl = item.pl
          groups[val].totalGastos = item.totalGastos
          groups[val].aduana = item.aduana
          groups[val].totalCargos = item.totalCargos
          groups[val].totalUtilidad = item.totalUtilidad
          groups[val].saldo = item.saldo
          return groups;
        }, []);

        let dataFinal2 = Object.values(agrupado);

        setComments(dataFinal2);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_PROVEEDORES}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allProveedores = res.data;
          setProveedores(allProveedores);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
      .get(`${URL_CLIENTES}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allClientes = response.data;
          setClientes(allClientes);
        })
        .catch((err) => {
          console.log(err);
        });

      

  }, [selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return [
          a.numero,
          a.fecha,
          a.navieras,
          a.clientes,
          a.observaciones,
          a.totalCantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalGeneral),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Naviera",
          "Cliente",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Naviera: a.navieras,
          Cliente: a.clientes,
          Observaciones: a.observaciones,
          Cantidad: a.totalCantidad,
          Total: a.totalGeneral,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ReporteContenedores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ReporteContenedores",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Naviera",
          "Cliente",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Naviera",
          "Cliente",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return [
          a.numero,
          a.fecha,
          a.navieras,
          a.clientes,
          a.observaciones,
          a.totalCantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalGeneral),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Naviera",
          "Cliente",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Contenedores",
          email: mailTo,
          fileName: "ReporteContenedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }





  const headers = [
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("No. Referencia")}`, field: "numeroInterno", sortable: true },
    { name: `${t("Contenedor")}`, field: "contenedor", sortable: true },
    { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    { name: `${t("Naviera")}`, field: "navieras", sortable: true },
    { name: `${t("Cantidad")}`, field: "totalCantidad", sortable: true },
    { name: `${t("ETA Real")}`, field: "etaReal", sortable: true },
    { name: `${t("Cargos")}`, field: "totalCargos", sortable: true },
    { name: `${t("Gastos")}`, field: "totalGastos", sortable: true },
    { name: `${t("Utilidad")}`, field: "totalUtilidad", sortable: true },
    { name: `${t("Desgloce")}`, field: "desgloce", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numero.toString().includes(search) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.navieras.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.totalGastos.toString().includes(search) ||
          comment.totalCargos.toString().includes(search) ||
          comment.totalUtilidad.toString().includes(search) 
      );
    }
    if (selectedNaviera) {
      computedComments = computedComments.filter((e) =>
        e.idNavieras.includes(selectedNaviera)
      );
    }


    // if (selectedFechaInicio) {
    //   computedComments = computedComments.filter(
    //     (e) => e.fecha >= selectedFechaInicio
    //   );
    // }

    // if (selectedFechaFin) {
    //   computedComments = computedComments.filter(
    //     (e) => e.fecha <= selectedFechaFin
    //   );
    // }

    
    let TUt = computedComments.map((c) => parseFloat(c.totalUtilidad));
    let TU = TUt.reduce((t, total, index) => t + total, 0);
    setTotalUtilidad(TU);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field != "totalGastos" &&
      sorting.field != "totalCargos" &&
      sorting.field != "totalUtilidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
      sorting.field == "totalGastos" ||
      sorting.field == "totalCargos" ||
      sorting.field == "totalUtilidad" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
      sorting.field == "totalGastos" ||
      sorting.field == "totalCargos" ||
      sorting.field == "totalUtilidad" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedNaviera,
  ]);

  function jalaDesgloce(id){
    
    axios
      .get(`${URL_CARGOS_CONTENEDOR}Contenedor/${id}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allCargos = response.data;
          setCargos(allCargos);
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(`${URL_GASTOS_CONTENEDOR}Contenedor/${id}`, { 
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allGastos = response.data;
            setGastos(allGastos);
          })
          .catch((err) => {
            console.log(err);
          });
    
    
    
    toggleDesgloce()
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.contenedoresReporte ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
             
              <Button
                size="sm"
                href="/MenuContenedores"
                className="btn btn-danger"
                id="botonListado"
              >
                 {t("Regresar")}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{t("Enviar Listado Contenedores")}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
               {t("Enviar")}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">{t("Reporte Contenedores con Cargos")}</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>{t("Navieras")}</Label>

              <Input
                bsSize="sm"
                  type="select"
                  value={selectedNaviera}
                  required
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a.tipo === "Naviera"){
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>

            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroInterno}</td>
                      <td>{a.contenedor}</td>
                      <td>{a.clientes}</td>
                      <td>{a.navieras}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.totalCantidad)}</td>
                      <td>{a.etaReal}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalCargos)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalGastos)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalUtilidad)}</td>
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => {
                            jalaDesgloce(a._id);
                            setContenedor(a.contenedor);
                            setTotalGastos(a.totalGastos);
                            setTotalCargos(a.totalCargos);
                          }}
                        >
                          $
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">Total</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalUtilidad)}</td>
                <td></td>

              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>

          <Modal size="sm" isOpen={modalDesgloce} toggle={toggleDesgloce}>
            <ModalHeader toggle={toggleDesgloce}>
              <h4>{t("Desgloce Contenedor")} {contenedor}</h4>
            </ModalHeader>
            <ModalBody>
             
             <Label>CARGOS</Label>
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr>
                  <th>{t("Fecha")}</th>
                  <th>{t("Concepto")}</th>
                  <th>{t("Importe")}</th>
                </tr>
              </thead>
              <tbody>
                {cargos.map((a) => {
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.conceptosGastos[0].name}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}</td>
              
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td className="negrita">Total</td>
                  <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargos)}</td>
                </tr>
                    </tbody>
                    </Table>

                    <Label>GASTOS</Label>
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr>
                  <th>{t("Fecha")}</th>
                  <th>{t("Concepto")}</th>
                  <th>{t("Importe")}</th>
                </tr>
              </thead>
              <tbody>
                {gastos.map((a) => {
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.conceptosGastos[0].name}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}</td>
                    </tr>
                  );
                })}

                    <tr>
                      <td></td>
                      <td className="negrita">Total</td>
                      <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalGastos)}</td>
                    </tr>
                    </tbody>
                    </Table>
            </ModalBody>
          </Modal>

        </div>
      ) : undefined}
      <br />


      {loader}
    </>
  );
}

export default ReporteContenedoresCargos;
