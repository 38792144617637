import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import Header from '../../layout/Header/Header';
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';

function ReporteUtilidades() {
  const { user } = useContext(AuthContext);
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;

  const [bancos, setBancos] = useState(0);
  const [socios, setSocios] = useState(0);
  const [clientes, setClientes] = useState(0);
  const [proveedores, setProveedores] = useState(0);


  useMemo(() => {
    axios
    .get(
      `${URL_BANCOS}ReporteUtilidad`,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      }
    )
    .then((response) => {
      let allMovimientos = response.data;

      let agr = allMovimientos
        .reduce((total, movimiento) => total + (movimiento.abonos - movimiento.cargos), 0);
      
      setBancos(agr);
      
  
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(
      `${URL_SOCIOS}`,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      }
    )
    .then((response) => {
      let allMovimientos = response.data;

      let agr = allMovimientos.reduce((total, movimiento) => total + (movimiento.porRetirar - movimiento.realRetirado), 0);
      setSocios(agr);
  
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(
      `${URL_CLIENTES}`,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      }
    )
    .then((response) => {
      let allMovimientos = response.data;

      let agr = allMovimientos.reduce((total, movimiento) => total + movimiento.saldo, 0);
      setClientes(agr);
  
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(
      `${URL_PROVEEDORES}`,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      }
    )
    .then((response) => {
      let allMovimientos = response.data;

      let agr = allMovimientos.reduce((total, movimiento) => total + movimiento.saldo, 0);
      setProveedores(agr);
  
    })
    .catch((err) => {
      console.log(err);
    });

  }, []);



  return (
    <>
      <Header />  
      <br />
      <br />
      {user.contenedoresReporte ? (
        <div className='card container col-sm-8'>
          <Row>
            <Col md={8}>
              <Button
                size='sm'
                href='/MenuContenedores'
                className='btn btn-danger'
                id='botonListado'
              >
                Regresar
              </Button>
            </Col>
          </Row>

          <h3 align='center'>Reporte Utilidad</h3>


          <br />

          
          <Table>
            <tr>
              <th>Cuentas Por Cobrar</th>
              <td>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                }).format(clientes)}
              </td>
            </tr>
            <tr>
              <th>Saldo en Bancos</th>
              <td>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                }).format(bancos)}
              </td>
            </tr>
            <tr>
              <th>Retiros</th>
              <td>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                }).format(-socios)}
              </td>
            </tr>
            <tr>
              <th>Cuentas Por Pagar</th>
              <td>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                }).format(-proveedores)}
              </td>
            </tr>
            <tr>
              <th>Utilidad</th>
              <td>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                }).format(clientes + bancos - socios - proveedores)}
              </td>
            </tr>
          </Table>
        </div>
      ) : undefined}
      <br />

      {/* {loader} */}
    </>
  );
}

export default ReporteUtilidades;
