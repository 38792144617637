import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesContenedores() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menuContenedores ?(
      <div className="container">
        <br />
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menuContenedores ?(
            <Button href="/ListadoContenedores" className="botonesMenu" color="success">
              <i class="fas fa-box fa-7x"></i>
              <br />
              <br />
              {t("Contenedores")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-box fa-7x"></i>
          <br />
          <br />
          {t("Contenedores")}
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menuCatalogos ?(
            <Button href="/ConceptosGastos" className="botonesMenu" color="success">
              <i class="far fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Conceptos")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-list-alt fa-7x"></i>
          <br />
          <br />
          {t("Conceptos")}
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menuCatalogos ?(
            <Button href="/ListadoTerminales" className="botonesMenu" color="success">
              <i class="fas fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Terminales")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-alt fa-7x"></i>
          <br />
          <br />
          {t("Terminales")}
        </Button> }
          </Col>
        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menuCatalogos ?(
            <Button href="/ListadoProgramas" className="botonesMenu" color="success">
              <i class="fas fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Programas")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-alt fa-7x"></i>
          <br />
          <br />
          {t("Programas")}
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menuCatalogos ?(
            <Button href="/ListadoPatentes" className="botonesMenu" color="success">
              <i class="fas fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Patentes")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-alt fa-7x"></i>
          <br />
          <br />
          {t("Patentes")}
        </Button> }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesContenedores;
