import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Signup = function () {
  const { user } = useContext(AuthContext);
  const URL_SIGNUP = process.env.REACT_APP_URL_USERS;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [text, setText] = useState(false);
  const [tipo, setTipo] = useState("Admin");
  const [telefono, setTelefono] = useState();
  const [t, i18n] = useTranslation("global");


  const handleForm = (event) => {
    event.preventDefault();
    axios
      .post(
       URL_SIGNUP,
        {
          nombre: firstName,
          apellido: lastName,
          email,
          password,
          tipo,
          telefono
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  };



  return (
    <>
      <br />
      <br />
        <div className="card container">
          <h3 align="center">{t("Nuevo")} {t("Usuario")}</h3>
          <Form className="container" onSubmit={handleForm}>
          {/*  <Label>Tipo</Label>
             <Input
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                type="select"
                
              >
                <option value="">{("Selecciona")}</option>
                <option value="Admin">Admin</option>
                <option value="Proveedor">Proveedor</option>
                <option value="Forwarder">Forwarder</option>
                </Input> */}
              <Label>Nombre</Label>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                
              />
              <Label>Apellido</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                name="lastName"
                placeholder="Apellido"
              />
              <Label>Email</Label>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                placeholder="Email"
              />
              <Label>Telefono</Label>
              <Input
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                type="number"
                id="firstName"
                
              />
              <Label>Contraseña</Label>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                placeholder="Contraseña"
              />
            {user.usuarios_create?(
            <Button type="submit" className="btn btn-success">
            Dar de Alta
          </Button>
          ): (
            <Button type="submit" className="btn btn-success" disabled>
            Dar de Alta
          </Button>
          ) }

          </Form>
      </div>
    </>
  );
};

export default Signup;
