import React from 'react'
import * as XLSX from 'xlsx'
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

class GetDataFromExcelJusTInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoja: "",
      hojas:[],
      file: false
    };

    this.handleInputChange = this.handleInputChange.bind(this)
  }
  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const this2 = this
    this.setState({
      [name]: value
    })
    let hojas = []
    if (name === 'file') {
      let reader = new FileReader()
      reader.readAsArrayBuffer(target.files[0])
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {type: 'array'});

        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          hojas.push({
            data: XL_row_object,
            sheetName
          })
        })

        let A1 = hojas[0].data

        A1.map((a)=>{
 
                axios
                  .patch(
                    `${process.env.REACT_APP_URL_CONTENEDORES}/${a.ID}`,
                    {
                      etaReal: a.ETA,
                      observaciones: a.Observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  )
      
                  .then(() => {
                    
                    Swal.fire("Good job!", "Creado con exito", `success`);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                    console.log(error);
                  });


      })

        this2.setState({
          selectedFileDocument: target.files[0],
          hojas
        })
      }
    }

    
  } 


  
  render() {

    const {
      handleInputChange
    } = this
    return (
      <>
        <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            onChange={handleInputChange} 
            placeholder="Archivo de excel" 
        />
      </>
    );
  }
}

export default GetDataFromExcelJusTInput


